import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acShowSearchResults } from '@actions/acSearch';
import { useTranslation } from '@hooks/useTranslation';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { searchEvent } from '@utils/gtag';

import SearchIconSvg from '../../../../../../../public/static/common/search.svg';
import useStyles from './styles';

interface ISearchButtonProps {
  toggleSearchMobileMenu?: () => void;
}

const SearchButton: React.FunctionComponent<ISearchButtonProps> = ({ toggleSearchMobileMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch<TThunkDispatch>();
  const searchValue = useSelector<IStore, string>((state) => state.search.inputValue);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const buttonTooltipText = t('search.buttonTooltip');
  const buttonTitle = typeof buttonTooltipText === 'string' ? buttonTooltipText : '';

  const onShowSearchResults = useCallback(() => dispatch(acShowSearchResults()), [dispatch]);

  useEffect(() => {
    setDisabled(searchValue === '');
  }, [searchValue]);

  return (
    <button
      data-action="search"
      className={classes.button}
      title={buttonTitle}
      onClick={(e) => {
        if (!disabled) {
          onShowSearchResults();
          searchEvent(searchValue);
        }
      }}
      //  disabled={disabled}
    >
      <SearchIconSvg />
    </button>
  );
};

export default SearchButton;

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

export default makeStyles<Theme>((theme) => ({
  input: {
    '&$open': {
      backgroundColor: ColorsPalette.neutral2,
      borderColor: ColorsPalette.primary,
      borderWidth: '2px',
    },
    backgroundColor: ColorsPalette.neutral1,
    borderRadius: '12px 0px 0px 12px',
    borderStyle: 'solid',
    borderWidth: 0,
    color: ColorsPalette.tertiary,
    height: 40,
    outline: 'none',
    paddingBottom: '0.5rem',
    paddingLeft: '1rem',
    paddingTop: '0.5rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      backgroundColor: ColorsPalette.neutral2,
      borderColor: ColorsPalette.primary,
      borderWidth: '2px',
      fontSize: '16px',
      height: 48,
      padding: '12px 0 12px 16px',
    },
  },
  loadingIcon: {
    height: 24,
    margin: '0 auto',
    position: 'absolute',
    right: '2rem',
    top: 8,
    [theme.breakpoints.down('md')]: {
      top: 12,
    },
  },
  open: {},
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
}));

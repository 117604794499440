import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
  button: {
    color: '#80868b',
    cursor: 'pointer',
    display: 'inline-block',
    fill: 'currentcolor',
    height: '100%',
    lineHeight: '20px',
    outline: 'none',
    position: 'absolute',
    right: '1rem',
    top: '8px',
    verticalAlign: 'middle',
    width: '20px',
    [theme.breakpoints.down('md')]: {
      top: '12px',
    },
  },
}));

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';

export default makeStyles<Theme>((theme) => ({
  button: {
    '& svg': {
      '& path': {
        fill: '#DADADA',
      },
    },
    alignItems: 'center',
    backgroundColor: ColorsPalette.primary,
    borderRadius: '0px 12px 12px 0px',
    borderStyle: 'solid',
    borderWidth: 0,
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    margin: 0,
    minWidth: '56px',
    outline: 'none',
    padding: 0,
    width: '10%',
    [theme.breakpoints.down('md')]: {
      height: '48px',
    },
  },
}));

import React from 'react';
import { useDispatch } from 'react-redux';

import { acSearchMatchingProductsClear, acSearchValueChange } from '@actions/acSearch';
import { TThunkDispatch } from '@interfaces/index';

import useStyles from './styles';

const SearchInputClear = ({ inputRef }) => {
  const classes = useStyles();

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const changeSearchValue = (value: string) => dispatch(acSearchValueChange(value));
  const clearSearchMatchingProducts = () => dispatch(acSearchMatchingProductsClear());

  const handleClick = () => {
    changeSearchValue('');
    clearSearchMatchingProducts();
    setTimeout(() => {
      inputRef.current.focus();
    }, 500);
  };

  return (
    <span className={classes.button} onClick={handleClick}>
      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </span>
  );
};

export default SearchInputClear;

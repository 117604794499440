import { acPreGoToPage } from '@actions/acPageData';
import { generateSearchUrl } from '@components/layout/header/AppBar/Search/utils';
import * as actions from '@constants/actions/search';
import { mobileMode } from '@constants/config';
import { EGender, EGenderType } from '@constants/gender';
import { PageTypes } from '@constants/pageTypes';
import { RequestMethods } from '@constants/types';
import {
  getSearchCategoriesUrl,
  getSearchClearHistoryUrl,
  getSearchHistoryUrl,
  getSearchResultUrl,
  getSearchTrendingItemsUrl,
} from '@constants/urls';
import { ESessionStatuses } from '@interfaces/fetchService';
import {
  ISearchClosePanel, ISearchGenderChange,
  ISearchMatchingProductsClear,
  ISearchOpenPanel,
  ISearchValueChange,
} from '@interfaces/search/actions';
import { IStore } from '@interfaces/store';
import { mapTypeToGenderIds } from '@utils/gender';
import { pushOrReloadRoute } from '@utils/routes';
import { CALL_API } from 'services/SSRService';
import { addParamsToUrl } from 'services/SSRService/utils';

export const acSearchPanelOpen: () => ISearchOpenPanel = () => ({
  type: actions.SEARCH_PANEL_OPEN,
});

export const acSearchPanelClose: () => ISearchClosePanel = () => ({
  type: actions.SEARCH_PANEL_CLOSE,
});

export const acSearchValueChange: (value: string) => ISearchValueChange = (value) => ({
  payload: value,
  type: actions.SEARCH_VALUE_CHANGE,
});

export const acSearchMatchingProductsClear: () => ISearchMatchingProductsClear = () => ({
  type: actions.SEARCH_MATCHING_PRODUCTS_CLEAR,
});

type TGetTrendingItems = (params: { country: string; lang: string }) => any;
export const acSearchTrendingGet: TGetTrendingItems = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSearchTrendingItemsUrl(), {
      country,
      lang,
      size: '5',
    }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.SEARCH_TRENDING_GET_REQUEST,
      actions.SEARCH_TRENDING_GET_SUCCESS,
      actions.SEARCH_TRENDING_GET_FAILED,
    ],
  },
});

export const acSearchTrendingGetAction = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acSearchTrendingGet({ ...locale }));
};

type TSearchHistoryGet = (params: { country: string; lang: string; }) => any;
export const acSearchHistoryGet: TSearchHistoryGet = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSearchHistoryUrl(), {
      country,
      lang,
      size: '5',
    }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.SEARCH_HISTORY_GET_REQUEST, actions.SEARCH_HISTORY_GET_SUCCESS, actions.SEARCH_HISTORY_GET_FAILED],
  },
});

export const acSearchHistoryGetAction = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acSearchHistoryGet({ ...locale }));
};

type TSearchHistoryClear = (params: { country: string; lang: string; }) => any;
export const acSearchHistoryClear: TSearchHistoryClear = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSearchClearHistoryUrl(), {
      country,
      lang,
    }),
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.SEARCH_HISTORY_GET_REQUEST, actions.SEARCH_HISTORY_GET_SUCCESS, actions.SEARCH_HISTORY_GET_FAILED],
  },
});

export const acSearchHistoryClearAction = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acSearchHistoryClear({ ...locale }));
};

type TSearchResultGet = (params: {
  country: string;
  lang: string;
  textSearch: string;
  size: string;
  sexId: number | number[] | null;
}) => any;
export const acSearchResultGet: TSearchResultGet = ({ country, lang, size, textSearch, sexId = 1 }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSearchResultUrl(), {
      country,
      includeSizes: true,
      lang,
      sexId,
      size,
      textSearch,
    }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.SEARCH_RESULT_GET_REQUEST, actions.SEARCH_RESULT_GET_SUCCESS, actions.SEARCH_RESULT_GET_FAILED],
  },
});

export const acSearchResultGetAction = (textSearch: string) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode }, search: { gender } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  const sexId = mapTypeToGenderIds(gender, [EGender.boy, EGender.girl]);
  return dispatch(acSearchResultGet({ ...locale, textSearch, size: '4', sexId }));
};

export const acShowSearchResults = (value?: string) => (dispatch, getState: () => IStore) => {
  const { pageData: { data: { pages } }, search: { gender, inputValue } } = getState();
  dispatch(acSearchPanelClose());
  if (mobileMode) {
    dispatch(acPreGoToPage(PageTypes.search));
  }
  const searchValue = value ? value : inputValue;
  const sexId = mapTypeToGenderIds(gender, [EGender.boy, EGender.girl]);
  pushOrReloadRoute(generateSearchUrl(pages, searchValue, sexId));
  return Promise.resolve(null);
};

type TSearchCategoriesGet = (params: {
  country: string;
  lang: string;
  text: string;
  sexId: number | number[] | null;
}) => any;
export const acSearchCategoriesGet: TSearchCategoriesGet = ({ country, lang, text, sexId }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getSearchCategoriesUrl(), {
      country,
      lang,
      sexId,
      text,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.SEARCH_CATEGORY_GET_REQUEST, actions.SEARCH_CATEGORY_GET_SUCCESS, actions.SEARCH_CATEGORY_GET_FAILED],
  },
});

export const acSearchCategoriesGetAction = (text: string) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode }, search: { gender } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };

  const sexId = mapTypeToGenderIds(gender, [EGender.boy, EGender.girl]);
  return dispatch(acSearchCategoriesGet({ ...locale, text, sexId }));
};

export const acSearchGenderChange: (value: EGenderType) => ISearchGenderChange = (value) => ({
  payload: value,
  type: actions.SEARCH_GENDER_CHANGE,
});

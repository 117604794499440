import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acSearchPanelOpen } from '@actions/acSearch';
import SearchButton from '@components/layout/header/AppBar/Search/SearchInput/SearchButton/SearchButton';
import SearchInput from '@components/layout/header/AppBar/Search/SearchInput/SearchInput';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

const styles = makeStyles(() => ({
  inputArea: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    zIndex: 5,
  },
}));

interface ISearchInputWithButtonProps {
  clearable?: boolean;
  className?: string;
  openTrigger?: boolean;
}

const SearchInputWithButton: React.FunctionComponent<ISearchInputWithButtonProps> = ({
  clearable,
  className,
  openTrigger = false,
}) => {
  const classes = styles();

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const isOpenSearchPanel = useSelector((state: IStore) => state.search.isOpenPanel);
  const openSearchPanel = useCallback(() => dispatch(acSearchPanelOpen()), [dispatch]);

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (openTrigger && !isOpenSearchPanel) {
      e.preventDefault();
      e.stopPropagation();
      openSearchPanel();
    }
  };

  return (
    <div className={classNames(classes.inputArea, className)} onClick={onClick}>
      <SearchInput clearable={clearable} />
      <SearchButton />
    </div>
  );
};

export default SearchInputWithButton;

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  acSearchCategoriesGetAction,
  acSearchMatchingProductsClear,
  acSearchPanelOpen,
  acSearchResultGetAction,
  acSearchValueChange, acShowSearchResults,
} from '@actions/acSearch';
import useDebouncedFunction from '@hooks/useDebouncedFunction';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import useRefWithCallback from '@hooks/useRefWithCallback';
import { useTranslation } from '@hooks/useTranslation';
import LoadingIcon from '@icons/Loading';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import SearchInputClear from './SearchInputClear/SearchInputClear';
import useStyles from './styles';

interface ISearchInputProps {
  clearable?: boolean;
}

const SearchInput: React.FunctionComponent<ISearchInputProps> = ({ clearable = true }) => {
  const classes = useStyles();
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();

  const isOpenPanel = useSelector((state: IStore) => state.search.isOpenPanel);
  const inputValueFromStore = useSelector((state: IStore) => state.search.inputValue);
  const gender = useSelector((state: IStore) => state.search.gender);

  const loadingCategories = useSelector<IStore, boolean>((state: IStore) => state.search.loadingCategories);
  const loadingProducts = useSelector<IStore, boolean>((state: IStore) => state.search.loadingProducts);

  const [toggle, refCallback, inputRef] = useRefWithCallback();

  const openSearchPanel = () => dispatch(acSearchPanelOpen());
  const changeSearchValue = (value: string) => dispatch(acSearchValueChange(value));
  const onShowSearchResults = (value: string) => dispatch(acShowSearchResults(value));

  const onGetSearchResult = (textSearch: string) => {
    if (textSearch) {
      dispatch(acSearchResultGetAction(textSearch));
      dispatch(acSearchCategoriesGetAction(textSearch));
    } else {
      dispatch(acSearchMatchingProductsClear());
    }
  };

  const [inputValue, setInputValue] = useState('');
  const { isEditMode, t, tString } = useTranslation();
  const inputPlaceholderText = tString('search.inputPlaceholderText');

  const hideKeyboardAndAllowScroll = () => {
    if (isOpenPanel && inputRef.current) {
      inputRef.current.blur();
      document.body.removeEventListener('touchmove', hideKeyboardAndAllowScroll);
    }
  };

  const handleFocus = () => {
    if (!isOpenPanel) {
      openSearchPanel();
    }
    document.body.addEventListener('touchmove', hideKeyboardAndAllowScroll);
  };

  const debouncedSearch = useDebouncedFunction(onGetSearchResult, 500);

  const handleChange = (e) => {
    const value = e.target.value;
    changeSearchValue(value);
    debouncedSearch(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      event.preventDefault();
      onShowSearchResults(value);
    }
  };


  useEffect(() => {
    if (isOpenPanel && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpenPanel, toggle]);

  useEffect(() => {
    setInputValue(inputValueFromStore);
  }, [isOpenPanel, inputValueFromStore]);

  useDidUpdateEffect(() => {
    dispatch(acSearchResultGetAction(inputValue));
  }, [gender]);

  return (
    <div className={classes.wrapper}>
      {isEditMode && t('search.inputPlaceholderText')}
      <input
        className={classNames(classes.input, { [classes.open]: isOpenPanel })}
        onFocus={handleFocus}
        placeholder={inputPlaceholderText}
        value={inputValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        ref={refCallback}
        name="search"
        aria-label="Search"
        autoComplete="off"
      />
      {(loadingCategories || loadingProducts) && (<LoadingIcon className={classes.loadingIcon} />)}
      {clearable && isOpenPanel && inputValue !== '' ? <SearchInputClear inputRef={inputRef} /> : ''}
    </div>
  );
};

export default SearchInput;
